import renderDriveServiceRows from "../assets/render/renderDriveServiceRows";
import renderRawContent from "../assets/render/renderRawContent";
import installDriveServiceScripts from "../assets/store/installDriveServiceScripts";
import installStyles from "../assets/store/installStyles";
import installGoogleTagManager from "../assets/store/installGoogleTagManager";
import { DeviceDetect } from "../assets/store/deviceDetect";
import { checkForHomepageWidget } from "../assets/store/checkForHomepageWidget";
import callEntryPointFunctions from "../assets/store/triggerRender";

const loadDriveServiceDivs = () => {
    const deviceDetect = new DeviceDetect({ isTest: false });
    try {
        const drive_service_divs = document.getElementsByClassName(
            "drive-service-specials-v5"
        );
        let drive_service_div_ids = [];
        for (let index = 0; index < drive_service_divs.length; index++) {
            const element = drive_service_divs[index];
            const id = element.id.replace("ds-div-", "");
            drive_service_div_ids.push(Number(id));
        }
        if (drive_service_div_ids.length > 0) {
            const body = JSON.stringify({
                href:
                    window.location.host === "localhost:3050" ||
                    window.location.host === "10.0.0.25:3000"
                        ? process.env.REACT_APP_TEST_HOST ??
                          "https://fixed-ops-digital.github.io/v5-ds-specials-client-app/"
                        : window.location.href,
                divs: drive_service_div_ids,
                languageOption: localStorage.getItem("languageOption"),
            });

            fetch(process.env.REACT_APP_DS_DIV_API, {
                method: "POST",
                mode: "cors",
                cache: "default",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body,
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    return Promise.reject(res);
                })
                .then((data) => {
                    window.DriveServiceSpecials = data;
                    let { drive_service_divs: ds_divs, client } = data;
                    if (data) {
                        renderDriveServiceRows(data, drive_service_divs);
                        renderRawContent();
                        let nonEmptyDivs = ds_divs.filter((div) => {
                            return div.div_rows.length;
                        });
                        if (
                            !checkForHomepageWidget(ds_divs) &&
                            nonEmptyDivs.length
                        )
                            installStyles(data);
                        if (process.env.REACT_APP_ENVIRONMENT !== "local") {
                            // This is for single page applications that keep the entrypoints on the page. It calls the functions already registered on the window to render components on page change
                            callEntryPointFunctions();
                            installDriveServiceScripts();
                        } else {
                            window.loadDevEnvironment();
                        }
                        // installVersion4ScriptTag();
                    } else {
                        throw new Error("No data received.");
                    }
                })
                .catch((error) => {
                    console.log("Something went wrong: " + error);
                });
        }
    } catch {
        console.log("Page did not load. Please try again later.");
    }
};

installGoogleTagManager();
window.loadDriveServiceDivs = loadDriveServiceDivs;
loadDriveServiceDivs();

export default loadDriveServiceDivs;
