const createRowElement = (type, row, element, multi = null) => {
    const rowElement = document.createElement("div");
    rowElement.id = row.id;
    rowElement.style.setProperty("margin", row.margin);
    rowElement.style.setProperty("padding", row.padding);
    rowElement.style.setProperty("background-color", row.background_color);
    rowElement.style.setProperty("text-align", row.text_alignment);
    rowElement.style.setProperty("color", row.text_color);
    if (row.link_color) {
        const styles = document.createElement("style");
        styles.innerHTML = `[id="${row.id}"] a { color: ${row.link_color} !important; }`;
        document.head.append(styles);
    }

    switch (type) {
        case "github_file_contents":
        case "terms_conditions":
        case "embedded_media":
        case "text":
        case "html":
        case "value_tracker":
            rowElement.className = "ds-raw-content-row";
            break;
        case "coupon_list":
            if (row.coupon_swiper === 1) {
                rowElement.className = "ds-coupon-swiper-row";
            } else {
                rowElement.className = "ds-coupon-widget-list-row";
            }
            break;
        case "coupon_detail":
            rowElement.className = "ds-coupon-detail-row";
            break;
        case "banner_swiper":
            rowElement.className = "ds-banner-swiper-row";
            break;
        case "hybrid_swiper":
            rowElement.className = "ds-hybrid-swiper-row";
            rowElement.style.padding = "15px 0";
            break;
        case "image_gallery":
            rowElement.className = "ds-image-gallery-row";
            break;
        case "internal_navbar":
            rowElement.className = "ds-internal-navbar-row";
            rowElement.style.setProperty("padding", "0");
            break;
        case "amenities_section":
        case "amenities":
            rowElement.className = "ds-amenities-section-row";
            break;
        case "content_block":
            rowElement.className = "ds-content-block-row";
            break;
        case "modal":
            rowElement.className = "ds-modal-row";
            break;
        case "css_styles":
            rowElement.className = "ds-custom-css-row";
            break;
        case "multiple_columns":
            rowElement.className = "ds-multiple-columns-row";
            rowElement.style.setProperty("display", "flex");
            let mobile = window.screen.width < 600;
            mobile
                ? rowElement.style.setProperty("flex-wrap", "wrap")
                : rowElement.style.setProperty("flex-wrap", "nowrap");
            rowElement.style.setProperty("overflow", "hidden");
            rowElement.style.setProperty("align-items", "center");
            break;
        case "map_section":
            rowElement.className = "ds-map-section-row";
            break;
        case "anchor_row":
            rowElement.className = "ds-anchor-row";
            break;
        case "recall_widget":
            rowElement.className = "ds-recall-widget-row";
            break;
        case "video":
            rowElement.className = "ds-video-row";
            rowElement.style.padding = "15px 0";
            break;
        case "footer":
            rowElement.className = "ds-branded-footer-row";
            break;
        default:
            break;
    }
    // Check to see if the row exists in the div and if it doesn't, append it
    let row_exists_in_div = element.querySelector("[id='" + row.id + "']");
    if (!row_exists_in_div) element.append(rowElement);
};

export default createRowElement;
