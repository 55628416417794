import installCSSLink from "../../assets/store/installCSSLink";
import getRowData from "../store/getRowData";
const important = "important";

function installTPScript(client) {
    let plugin_id = client.tp_active_plugin_id;
    console.log(plugin_id);
    if(!plugin_id) return;
    let script = document.createElement("script");
    script.src = `${process.env.REACT_APP_SNAP_URL}snap-${plugin_id}.js`;
    document.body.append(script);
}

const renderTextRow = (row, rowData) => {
    const { background_color, content, font_size, text_alignment, text_color } =
        rowData;
    const { client } = window.DriveServiceSpecials;
    const textDiv = document.createElement("div");
    textDiv.innerText = content;

    let defaultFontFamily = client.font_family ?? "inherit";
    if (defaultFontFamily && defaultFontFamily !== "inherit") {
        defaultFontFamily.install_links.forEach((link) => {
            installCSSLink(link, "fod-font-" + defaultFontFamily.name);
        });

        defaultFontFamily = defaultFontFamily.font_family_name;
    }

    if (background_color)
        textDiv.style.setProperty("background", background_color, important);
    if (defaultFontFamily)
        textDiv.style.setProperty("font-family", defaultFontFamily, important);
    if (font_size) textDiv.style.setProperty("font-size", font_size, important);
    if (text_alignment)
        textDiv.style.setProperty("text-align", text_alignment, important);
    if (text_color) textDiv.style.setProperty("color", text_color, important);
    row.append(textDiv);
};

const renderEmbeddedMedia = (row, rowData) => {
    const { embedded_media } = rowData;
    const iframe = document.createElement("iframe");
    iframe.src = embedded_media;
    const outterWrapper = document.createElement("div");
    iframe.style.width = "100%";
    iframe.style.height = "100%";
    outterWrapper.style.width = "100%";
    outterWrapper.style.height = "100%";
    outterWrapper.append(iframe);
    row.append(outterWrapper);
};

const renderTermsAndConditions = (row, rowData) => {
    const {
        terms_and_conditions,
        text_color,
        background_color,
        text_alignment,
        font_size,
    } = rowData;
    if (terms_and_conditions) {
        const { title, body } = terms_and_conditions;
        const { client } = window.DriveServiceSpecials;
        const parentDiv = document.createElement("div");
        const titleDiv = document.createElement("div");
        titleDiv.innerText = title;
        titleDiv.style.margin = "20px 20px 0 20px";
        const bodyDiv = document.createElement("div");
        bodyDiv.innerText = body;

        let defaultFontFamily = client.font_family ?? "inherit";
        if (defaultFontFamily && defaultFontFamily !== "inherit") {
            defaultFontFamily.install_links.forEach((link) => {
                installCSSLink(link, "fod-font-" + defaultFontFamily.name);
            });

            defaultFontFamily = defaultFontFamily.font_family_name;
        }

        parentDiv.style.backgroundColor = "transparent";
        parentDiv.style.color = text_color ?? "#000000";
        parentDiv.style.textAlign = text_alignment ?? "center";
        parentDiv.style.fontFamily = defaultFontFamily;
        parentDiv.style.fontSize = font_size ?? "inherit";
        parentDiv.style.display = "flex";
        parentDiv.style.flexDirection = "column";
        parentDiv.style.justifyContent = "center";
        parentDiv.style.alignItems = "center";
        bodyDiv.style.maxWidth = "1200px";
        bodyDiv.style.margin = "20px 10px";

        parentDiv.append(titleDiv);
        parentDiv.append(bodyDiv);
        row.append(parentDiv);
    }
};

const renderValueTrackerRow = (row, rowData) => {
    const div = document.createElement("div");
    div.style.width = "100%";
    div.id = "tp-so-value-tracker-container";
    row.append(div);
}

const renderRawContent = () => {
    const { client, drive_service_divs } = window.DriveServiceSpecials;
    const htmlDivs = document.getElementsByClassName("ds-raw-content-row");

    let defaultFontFamily = client.font_family ?? "inherit";
    if (defaultFontFamily && defaultFontFamily !== "inherit") {
        defaultFontFamily.install_links.forEach((link) => {
            installCSSLink(link, "fod-font-" + defaultFontFamily.name);
        });

        defaultFontFamily = defaultFontFamily.font_family_name;
    }

    for (let index = 0; index < htmlDivs.length; index++) {
        const row = htmlDivs[index];
        const parent = drive_service_divs.find(
            (div) =>
                div.id === parseInt(row.parentElement.id.replace("ds-div-", ""))
        );
        let rowData = getRowData(parent.div_rows, parseInt(row.id));
        const {
            content,
            type,
            padding,
            margin,
            text_alignment,
            media_height,
            media_width,
        } = rowData;
        row.style.padding = padding;
        row.style.margin = margin;
        row.style.height = media_height;
        row.style.width = media_width;
        row.style.textAlign = text_alignment;
        if (type === "github_file_contents")
            row.innerHTML = window.atob(content);
        else if (type === "html") {
            row.innerHTML = content;
            row.style.fontFamily = defaultFontFamily;
        } else if (type === "text") renderTextRow(row, rowData);
        else if (type === "embedded_media") renderEmbeddedMedia(row, rowData);
        else if (type === "terms_conditions")
            renderTermsAndConditions(row, rowData);
        else if(type === "value_tracker") {
            renderValueTrackerRow(row, rowData);
            installTPScript(client);
        }
    }
};

export default renderRawContent;
